import LogoSlider from "@components/shared/slider/logoSlider";
import React, { useEffect } from "react";
import LandingNote from "../components/landingNote";

import LayOut from "@components/layout";
import FeatureColumn from "@components/pages-components/features/featureColumn";
import Hero from "@components/shared/hero";
import SEO from "@components/shared/seo";
import { Query, SanitySecurityPageConnection } from "@graphql-types";
import { useStore } from "@state/store";
import { isNotNullOrUndefined } from "@util/assertions";
import { useRegionChanged } from "@util/hooks";
import { Container } from "@util/standard";
import { PageProps, graphql } from "gatsby";

interface Data extends Query {
  altRegions: SanitySecurityPageConnection;
  pageContext: any;
}

interface Props extends PageProps {
  data: Data;
}

const Security = (props: Props) => {
  const { ipLookup } = useStore();

  useRegionChanged("security");

  const sanitySecurityPage = props.data.allSanitySecurityPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(
    alt => alt._rawRegion
  );
  const filtered = props.data?.sanityUseApprovedsIndex?.logosArray
    ?.filter(logoBlock =>
      logoBlock?.locationsArray
        ?.map(location => location?.value)
        //@ts-ignore
        .includes(props.pageContext?.iban)
    )
    ?.filter(isNotNullOrUndefined);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (sanitySecurityPage == null) {
    return null;
  }

  return (
    <>
      <SEO
        seoData={sanitySecurityPage.seo}
        alternateRegions={alternateRegions}
        //@ts-ignore
        slug={props.pageContext.slug}
      />

      <LayOut>
        <div id="security_page">
          <Hero singleHeroContent={sanitySecurityPage.hero} />
          <LogoSlider
            sliderHeader={sanitySecurityPage?.complianceLogos?.sliderHeader}
            sliderButton={sanitySecurityPage?.complianceLogos?.sliderLink}
            isButton
            logos={filtered && filtered[0]}
          />
          {!ipLookup && <LandingNote />}
          <Container margin="50px auto">
            {sanitySecurityPage.featureColumns?.map(column => {
              if (column) {
                return <FeatureColumn key={column._key} column={column} />;
              }

              return null;
            })}
          </Container>
          {sanitySecurityPage.penaltiseBlock && (
            <FeatureColumn column={sanitySecurityPage.penaltiseBlock} border />
          )}
        </div>
      </LayOut>
    </>
  );
};

export default Security;

export const query = graphql`
  query securityQuery($iban: String) {
    allSanitySecurityPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        featureColumns {
          ...sanityImageTextColumn
        }
        complianceLogos {
          ...sanityAccreditedSliderBlock
        }
        penaltiseBlock {
          ...sanityImageTextColumn
        }
      }
    }
    altRegions: allSanitySecurityPage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
    sanityUseApprovedsIndex {
      logosArray {
        _key
        locationsArray {
          _key
          name
          value
        }
        title
        logos {
          _key
          title
          image {
            altText
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 150)
            }
          }
        }
      }
    }
  }
`;
