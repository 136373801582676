import React from "react";
import EmailForm from "./forms/join-form";
import EmailFormCountry from "./forms/join-form-country";
import { useStore } from "@state/store";
import { Container } from "@util/standard";
import styled from "styled-components";
import { useLandingNoteQuery } from "@api";
import { useHasMounted } from "@util/hooks";
import Image from "@components/shared/sub/image";

const StyledLandingNote = styled(Container)`
  margin: 50px 0;
`;

const StyledInnerContainer = styled(Container)`
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10rem;
  align-items: center;
  max-width: 900px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 90%;
    margin: auto;
    .second-column {
      grid-row: 1/2;
    }
  }
`;

const StyledSecondColumn = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 3.6rem;
    line-height: 3.6rem;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  form {
    max-width: 55rem;
  }

  @media screen and (max-width: 600px) {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`;

const LandingNote = () => {
  const { ipLookup } = useStore();
  const { sanityLandingNote } = useLandingNoteQuery();

  // when the current location set to global or set show section to false, it won't show the section/empty div
  if (!sanityLandingNote) return null;

  const isMounted = useHasMounted();

  return (
    <>
      {isMounted && !ipLookup.isIncludedCountry && !ipLookup.failedFetch && (
        <StyledLandingNote>
          <StyledInnerContainer>
            {sanityLandingNote.image && sanityLandingNote.image.asset && (
              <Image data={sanityLandingNote.image} width="100%" />
            )}

            <StyledSecondColumn>
              {ipLookup.countryName ? (
                <h2>
                  {sanityLandingNote.sectionTitle} {ipLookup.countryName}
                </h2>
              ) : (
                <h2>Celo hasn't landed here yet</h2>
              )}
              <p>{sanityLandingNote.description}</p>
              {ipLookup.countryName ? (
                <EmailForm isDark />
              ) : (
                <EmailFormCountry />
              )}
            </StyledSecondColumn>
          </StyledInnerContainer>
        </StyledLandingNote>
      )}
    </>
  );
};

export default LandingNote;

/*

    */
