import React, { useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { ReCaptcha } from "react-recaptcha-v3";
import { useStore } from "@state/store";
import { colors } from "@util/constants";
import styled from "styled-components";

const StyledButton = styled.button`
  background-color: ${colors.pink};
  cursor: pointer;
  padding: 1.2rem 4rem;
  font-size: 1.6rem;
  border: 0;
  color: white;
  border-radius: 10rem;
  font-family: "header light";
  text-align: center;
  transition: padding 0.3s cubic-bezier(0, 0, 0, 1.59);
  &:hover {
    padding: 1.2rem 3.5rem;
  }
  &:focus {
    outline: none;
  }
  .ant-spin {
    margin-top: 0.7rem i {
      background-color: white;
    }
  }
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  overflow: hidden;
  max-width: 100%;
  margin-top: 10px;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 80%;
    min-height: 200px;
    margin-bottom: 20px;
  }
`;

const StyledInput = styled.input`
  border: 0;
  flex-grow: 1;
  padding: 1.2rem 3rem;
  width: 60%;
  &:focus {
    outline: none;
  }
`;

const JoinFormCountry = () => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const [placeHolder, setPlaceHolder] = useState("Enter your email");
  const [formDisable, setFormDisable] = useState(true);
  const { ipLookup } = useStore();

  const [country, setCountry] = useState(ipLookup.countryName);

  const handleSubmit = e => {
    e.preventDefault();

    setSubmitting(true);

    axios
      .post(
        "https://getform.io/f/59829da6-385e-4973-9b24-9b57e2195bbe",
        { email, currentCountryName: country },
        {
          headers: { Accept: "application/json" },
        }
      )
      .then(res => {
        setEmail("");
        setButtonText("Sent");
        setPlaceHolder("Your email address has been sent.");
        setSubmitting(false);
      })
      .catch(err => {
        setEmail("");
        setButtonText("Try again");
        setPlaceHolder("Sorry, submit falied, try again later");
        setSubmitting(false);
      });
  };

  const verifyCallback = recaptchaToken => {
    if (recaptchaToken) setFormDisable(false);
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <StyledInput
          type="email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder={placeHolder}
          aria-required="true"
        />
        <StyledInput
          type="country"
          required
          onChange={e => setCountry(e.target.value)}
          value={country}
          placeholder="Country*"
          aria-required="true"
        />

        <StyledButton type="submit" disabled={formDisable}>
          {submitting ? <Spin /> : buttonText}
        </StyledButton>
      </StyledForm>
      <ReCaptcha
        sitekey={process.env.GATSBY_RECAPCHA_KEY}
        verifyCallback={verifyCallback}
      />
    </>
  );
};

export default JoinFormCountry;
